<template>
  <main class="set-new-password-page">
    <img
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1cc9db6ba57e8517a575aa02e3ceff6a1c08e9226a26e9359787afc28fdf1680?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
      alt="Logo"
      class="logo"
    />
    <h1 class="page-title">Reset your password</h1>
    <p class="password-instruction">
      Your new password must be different from previously used passwords.
    </p>
    <form @submit.prevent="reset" class="password-form">
      <div class="form-group">
        <label for="password" class="form-label">New Password</label>
        <div class="input-wrapper">
          <input
            :type="showPass ? 'text' : 'password'"
            id="password"
            v-model="Newpassword"
            class="form-input"
            required
            v-validate="'required'"
          />
          <button type="button" @click="showPassword" class="btn-showPassword">
            <img
              src="@/assets/icon/visibility.svg"
              v-if="showPass"
              class="material-icons-outlined text-muted"
            />
            <img
              v-else
              src="@/assets/icon/visibility_off.svg"
              class="material-icons-outlined text-muted"
            />
          </button>
        </div>
        <span class="text-danger text-sm" v-show="errors.has('new password')">{{
          errors.first("new password")
        }}</span>
      </div>

      <div class="form-group">
        <label for="confirmPassword" class="form-label">Confirm Password</label>
        <div class="input-wrapper">
          <input
            :type="showConfirmPass ? 'text' : 'password'"
            id="confirmPassword"
            v-model="Confirmpassword"
            class="form-input"
            required
            v-validate="'required'"
          />
          <button
            type="button"
            @click="showConfirmPassword"
            class="btn-showConfirmPassword"
          >
            <img
              src="@/assets/icon/visibility.svg"
              v-if="showConfirmPass"
              class="material-icons-outlined text-muted"
            />
            <img
              v-else
              src="@/assets/icon/visibility_off.svg"
              class="material-icons-outlined text-muted"
            />
          </button>
        </div>
        <span
          class="text-danger text-sm"
          v-show="errors.has('confirm password')"
          >{{ errors.first("confirm password") }}</span
        >
      </div>

      <button type="submit" class="submit-button">Reset Password</button>
    </form>
    <button @click="back" class="back-button">
      <img
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/c918458b20ad03756083c6511666c6b6b2af2db54a6ef46caac6620a04902543?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
        alt=""
        class="back-icon"
      />
      <span>Back to log in</span>
    </button>
  </main>
</template>

<script>
/* eslint-disable */
import Auth from "@/store/Auth.js";

export default {
  data() {
    return {
      Newpassword: null,
      Confirmpassword: null,
      token: "",
      showPass: true,
      showConfirmPass: true,
    };
  },

  methods: {
    showPassword() {
      this.showPass = !this.showPass;
    },

    showConfirmPassword() {
      this.showConfirmPass = !this.showConfirmPass;
    },

    reset() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.Newpassword === this.Confirmpassword) {
            const payload = {
              token: this.token,
              password: this.Newpassword,
              password_confirmation: this.Confirmpassword,
            };

            Auth.updatePassword(payload)
              .then((response) => {
                this.$toast.success(response.data.message);

                localStorage.removeItem("accessToken");
                localStorage.removeItem("userInfo");
                // localStorage.removeItem("me");

                this.$router.push({ name: "Login" });
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.$toast.error(
              "New Password and Confirm Password does not match!"
            );
          }
        } else {
          this.$toast.error("Please fill in all the fields");
        }
      });
    },

    back() {
      this.$router.push({ name: "Login" });
    },
  },

  created() {
    this.token = this.$route.query.token;
  },
};
</script>
<style scoped>
.set-new-password-page {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 80px 329px;
}

.logo {
  width: 88px;
  height: auto;
}

.page-title {
  color: #383a3e;
  margin-top: 20px;
  font: 600 24px Poppins, sans-serif;
}

.password-instruction {
  color: #86888d;
  margin-top: 8px;
  font: 400 14px/24px Poppins, sans-serif;
  text-align: center;
}

.password-form {
  width: 100%;
  max-width: 500px;
  margin-top: 24px;
}

.form-group {
  margin-bottom: 42px;
}

.form-label {
  color: #86888d;
  font: 500 14px/1 Poppins, sans-serif;
  display: block;
  margin-bottom: 8px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: #fafbfc;
  min-height: 56px;
  padding: 16px 20px;
}

.form-input {
  flex-grow: 1;
  border: none;
  background: transparent;
  font: 500 16px Poppins, sans-serif;
  color: #383a3e;
}

.btn-showPassword,
.btn-showConfirmPassword {
  border: none;
  background: none;
  cursor: pointer;
}

.submit-button {
  width: 100%;
  border-radius: 4px;
  background: #0e4485;
  color: #fff;
  padding: 16px;
  font: 500 16px Poppins, sans-serif;
  border: none;
  cursor: pointer;
  margin-top: 32px;
}

.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #0e4485;
  font: 500 14px/1 Poppins, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 32px;
}

.back-icon {
  width: 18px;
  height: 18px;
}

@media (max-width: 991px) {
  .set-new-password-page {
    padding: 100px 20px;
  }

  .password-instruction {
    max-width: 100%;
  }

  .password-form {
    max-width: 100%;
  }
}
</style>
